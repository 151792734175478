import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const InputLabel = styled.label`
  font-size: 16px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

function Home() {
  const location = useLocation();

  const [balance, setBalance] = useState(location?.state?.balance ?? '');
  const [payment, setPayment] = useState(location?.state?.payment ?? '');
  const [interestRate, setInterestRate] = useState(location?.state?.interestRate ?? 0);
  const [monthlyFee, setMonthlyFee] = useState(location?.state?.monthlyFee ?? 0);
  const [additionalPayment, setAdditionalPayment] = useState(location?.state?.additionalPayment ?? 0);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      balance,
      payment,
      interestRate,
      monthlyFee,
      additionalPayment,
    };

    navigate('/result', { state: data });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <InputLabel>Loan Balance</InputLabel>
        <Input
          type="number"
          value={balance}
          onChange={(event) => setBalance(event.target.value)}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Monthly Payment</InputLabel>
        <Input
          type="number"
          value={payment}
          onChange={(event) => setPayment(event.target.value)}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Interest Rate</InputLabel>
        <Input
          type="number"
          step="0.01"
          value={interestRate}
          onChange={(event) => setInterestRate(event.target.value)}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Fees included in Monthly Payment i.e Escrow</InputLabel>
        <Input
          type="number"
          value={monthlyFee}
          onChange={(event) => setMonthlyFee(event.target.value)}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Additional Payment</InputLabel>
        <Input
          type="number"
          value={additionalPayment}
          onChange={(event) => setAdditionalPayment(event.target.value)}
          required
        />
      </InputWrapper>
      <Button type="submit">Calculate</Button>
    </Form>
  );
}

export default Home;
