import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const ResultHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ResultItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;

const ResultLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const ResultValue = styled.span`
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

function calculateAmortization(balance, payment, interestRate, monthlyFee, additionalPayment, includeFeesInPayment) {
  const monthlyInterestRate = interestRate / 12 / 100;
  let currentBalance = balance;
  let totalInterest = 0;
  let totalFees = 0;
  let totalPayment = 0;
  let months = 0;

  const amortizationSchedule = [];

  while (currentBalance > 0) {
    months++;

    const interest = currentBalance * monthlyInterestRate;
    totalInterest += interest;

    const principal = Math.min(payment - interest - monthlyFee + additionalPayment, currentBalance);
    currentBalance -= principal;

    totalFees += monthlyFee;
    totalPayment += principal + interest + monthlyFee + additionalPayment;

    amortizationSchedule.push({
      month: months,
      payment: principal + interest + monthlyFee,
      principal,
      interest,
      monthlyFee,
      balance: currentBalance,
    });
  }

  return {
    // monthlyPayment: payment + (includeFeesInPayment ? 0 : monthlyFee),
    monthlyPayment: amortizationSchedule[0].payment,
    totalInterest,
    totalFees: includeFeesInPayment ? 0 : totalFees,
    totalPayment,
    months,
    amortizationSchedule,
  };
}

function Result() {
  const location = useLocation();

  const { balance, payment, interestRate, monthlyFee, additionalPayment, includeFeesInPayment } = location.state;

  const result = calculateAmortization(Number(balance), Number(payment), Number(interestRate), Number(monthlyFee), Number(additionalPayment), includeFeesInPayment);

  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate('/', { state: { balance, payment, interestRate, monthlyFee, additionalPayment, includeFeesInPayment } });
  };

  return (
    <ResultWrapper>
      <ResultHeader>Loan Amortization Schedule</ResultHeader>
      <ResultItem>
        <ResultLabel>Monthly Payment:</ResultLabel>
        <ResultValue>${result.monthlyPayment.toFixed(2)}</ResultValue>
      </ResultItem>
      <ResultItem>
        <ResultLabel>Total Interest:</ResultLabel>
        <ResultValue>${result.totalInterest.toFixed(2)}</ResultValue>
      </ResultItem>
      {result.totalFees > 0 && (
        <ResultItem>
          <ResultLabel>Total Fees:</ResultLabel>
          <ResultValue>${result.totalFees.toFixed(2)}</ResultValue>
        </ResultItem>
      )}
      <ResultItem>
        <ResultLabel>Total Payment:</ResultLabel>
        <ResultValue>${result.totalPayment.toFixed(2)}</ResultValue>
      </ResultItem>
      <ResultItem>
        <ResultLabel>Months to Payoff:</ResultLabel>
        <ResultValue>{result.months}</ResultValue>
      </ResultItem>
      {result.months > 12 && (
        <ResultItem>
            <ResultLabel>Years to Payoff:</ResultLabel>
            <ResultValue>{(result.months / 12).toFixed(2)}</ResultValue>
        </ResultItem>
      )}
      <Button onClick={handleEditClick}>Edit</Button>
      <table>
        <thead>
          <tr>
            <th>Month</th>
            <th>Payment</th>
            <th>Principal</th>
            <th>Interest</th>
            {includeFeesInPayment && <th>Fees</th>}
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {result.amortizationSchedule.map((scheduleItem) => (
            <tr key={scheduleItem.month}>
              <td>{scheduleItem.month}</td>
              <td>${scheduleItem.payment.toFixed(2)}</td>
              <td>${scheduleItem.principal.toFixed(2)}</td>
              <td>${scheduleItem.interest.toFixed(2)}</td>
              {includeFeesInPayment && <td>${scheduleItem.fees.toFixed(2)}</td>}
              <td>${scheduleItem.balance.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ResultWrapper>
  );
}

export default Result;

